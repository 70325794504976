@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-ExtraBoldItalic.ttf');
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Bold.ttf');
  font-weight: 700;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-BoldItalic.ttf');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-SemiBold.ttf');
  font-weight: 600;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-SemiBoldItalic.ttf');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Medium.ttf');
  font-weight: 500;
}

* {
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 500;
}

.grecaptcha-badge {
  right: -1860px !important;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
